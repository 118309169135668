// 添加样式依赖
import '@/common/css/index.scss';
import './index.scss';
import 'swiper/dist/css/swiper.min.css';
// 添加js依赖
import $ from 'jquery';
import Swiper from 'swiper/dist/js/swiper.min.js';

// function _throttle(fn, time) {
//   let _self = fn,
//     timer,
//     firstTime = true; //记录是否是第一次执行的flag
//   return function() {
//     let args = arguments, //解决闭包传参问题
//       _me = this; //解决上下文丢失问题
//     if (firstTime) {
//       //若是第一次，则直接执行
//       _self.apply(_me, args);
//       return (firstTime = false);
//     }
//     if (timer) {
//       //定时器存在，说明有事件监听器在执行，直接返回
//       return false;
//     }
//     timer = setTimeout(function() {
//       clearTimeout(timer);
//       timer = null;
//       _self.apply(_me, args);
//     }, time || 200);
//   };
// }

$(function() {
  var $header = $('.header.fixed');
  var $body = $('body, html');

  function scroll() {
    var scrollTop = $body.scrollTop();

    if (scrollTop > 90) {
      $header.css({ top: 0, transition: 'top 0.2s' });
    } else {
      $header.css({ top: '-90px', transition: 'none' });
    }
  }

  new Swiper('.swiper-container', {
    // direction: 'vertical', // 垂直切换选项
    autoplay: true,
    loop: true,
    effect: 'fade', // 如果需要分页器 //可选选项，自动滑动 // 循环模式选项 // 切换效果
    pagination: { el: '.swiper-pagination', clickable: true }
  });
  // 滚动事件
  scroll();
  window.addEventListener('scroll', scroll);
});
